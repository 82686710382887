import React, { forwardRef, useEffect, useRef, useState, useImperativeHandle } from 'react'
import PropTypes from 'prop-types';
import showModalDefault from "../helpers/ui/ModalDefault";
import Messages from "./Messages";

const Input = forwardRef(function Input({
                                            autofocus,
                                            value,
                                            hintType = 'block',
                                            showBlockHintOnError,
                                            error,
                                            hint,
                                            visualVariables,
                                            placeholder,
                                            required,
                                            iconLeft,
                                            iconRight,
                                            type = 'text',
                                            name, disabled, autoComplete,
                                            pattern, title, maxLength, minLength, ariaLabel, min, max, id,
                                            onFocus,
                                            onBlur,
                                            isValid, className, style,
                                            hideMessages,
                                            ...props
                                        }, ref) {

    const hoverLeaveTimeout = useRef();
    const floatingHintContainer = useRef();
    const hintTrigger = useRef();
    const inputField = useRef();
    const inputContainer = useRef();
    // allow to use ref both for forwarding and inside component
    useImperativeHandle(ref, () => inputField.current, [])

    const [variables, setVariables] = useState({});
    // const [upperPlaceholder, setUpperPlaceholder] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showHint, setShowHint] = useState(false);

    const defineVisualVariables = () => {
        if (!visualVariables) return;
        setVariables({
            // geometry
            '--input-height': visualVariables['height'],
            '--input-border-radius': visualVariables['borderRadius'],
            // colors
            '--input-color-background': visualVariables['colorBackground'],
            '--input-color-background-hint_block': visualVariables['colorBackgroundHintBlock'],
            '--input-color-border': visualVariables['colorBorder'],
            '--input-color-border-hover': visualVariables['colorBorderHover'],
            '--input-color-accent': visualVariables['colorAccent'],
            '--input-color-icon': visualVariables['colorIcon'],
            '--input-color-icon-password': visualVariables['colorIconPassword'],
            // colors text
            '--input-color-font': visualVariables['colorFont'],
            '--input-color-font-label': visualVariables['colorFontLabel'],
            '--input-color-font-label-upper': visualVariables['colorFontLabelUpper'],
            '--input-color-font-hint_block': visualVariables['colorFontHintBlock'],
            // textarea
            '--max-height-textarea': visualVariables['maxHeight'],
            '--min-height-textarea': visualVariables['minHeight'],
            '--max-width-textarea': visualVariables['maxWidth'],
            '--min-width-textarea': visualVariables['minWidth'],
        })
    }

    const handleFloatingHintHover = e => {

        const triggerHovered = hintTrigger.current?.contains(e.target);
        const hintHovered = floatingHintContainer.current?.contains(e.target);

        if (triggerHovered || hintHovered) {
            clearTimeout(hoverLeaveTimeout.current);
            setShowHint(true)
        }
    }

    const handleFloatingHintOut = e => {

        const triggerLeave = !hintTrigger.current?.contains(e.target);
        const hintLeave = !floatingHintContainer.current?.contains(e.target);
        // logic here is not that obvious
        // when mouse leaves container, mouseover event is still online and it resets timeout in this.handleFloatingHintHover
        // so we don't need to handle mouseout for both containers, just check them one by one in logical "or"
        if (triggerLeave || hintLeave) {
            clearTimeout(hoverLeaveTimeout.current);
            hoverLeaveTimeout.current = setTimeout(() => setShowHint(false), 1000);
        }
    }

    const handleInvalidValidationHTML5 = e => setShowHint(true);

    useEffect(() => {
        if (!!autofocus && !!inputField?.current?.focus) inputField.current.focus();

        return () => {
            clearTimeout(hoverLeaveTimeout.current);
            inputContainer.current?.removeEventListener('mouseover', handleFloatingHintHover);
            inputContainer.current?.removeEventListener('mouseout', handleFloatingHintOut);
            inputField.current?.removeEventListener('invalid', handleInvalidValidationHTML5);
        }
    }, []);

    // @todo chrome autofill
    // if (typeof window !== 'undefined') {
    //     const pseudo = inputField.current ? window.getComputedStyle(inputField.current, ':-webkit-autofill') : undefined;
    //
    //     function matches( node, selector ) {
    //         const nativeMatches = ( node.matches || node.msMatchesSelector );
    //         try {
    //             return nativeMatches.call( node, selector );
    //         } catch ( error ) {
    //             return false;
    //         }
    //
    //     }
    //
    //     console.log(matches( inputField, ':-webkit-autofill' ));
    // }

    // visual variables can be passed dynamically depending on theme or events
    useEffect(() => {defineVisualVariables()}, [visualVariables]);

    useEffect(() => {
        if (hintType === 'floating') {
            inputContainer.current?.addEventListener('mouseover', handleFloatingHintHover)
            inputContainer.current?.addEventListener('mouseout', handleFloatingHintOut)
        } else {
            inputContainer.current?.removeEventListener('mouseover', handleFloatingHintHover)
            inputContainer.current?.removeEventListener('mouseout', handleFloatingHintOut)
        }
    }, [hintType]);
    // trigger block hint show on HTML5 validation fail
    useEffect(() => {
        if (
            !!showBlockHintOnError
            && Array.isArray(showBlockHintOnError)
            && !!showBlockHintOnError.length
            && showBlockHintOnError.includes('html5')
            && hintType === 'block'
        ) {
            inputField?.current?.addEventListener('invalid', handleInvalidValidationHTML5)
        } else {
            inputField?.current?.removeEventListener('invalid', handleInvalidValidationHTML5)
        }
    }, [showBlockHintOnError, hintType]);

    useEffect(() => {
        if (
            !!showBlockHintOnError && Array.isArray(showBlockHintOnError) && !!showBlockHintOnError.length && showBlockHintOnError.includes('propError')
            && !!error && hintType === 'block'
            && !showHint
        ) {
            setShowHint(true);
        }
    }, [showBlockHintOnError, error, hintType]);

    const onChange = e => {
        if (!!props.onChange) props.onChange(e);
    };

    const renderBlockHint = () => {
        const showClass = showHint ? ' input-custom__hint__block--visible' : '';
        return (
            <div className={`input-custom__hint__block${showClass}`}>
                <div className='input-custom__hint__block__content'>{hint}</div>
            </div>
        )
    };

    const renderFloatingHint = () => {
        const showClass = showHint ? ' input-custom__hint__floating--visible' : '';
        return (
            <div
                className={`input-custom__hint__floating${showClass}`}
                ref={floatingHintContainer}
            >
                <div className='input-custom__hint__floating__content'>{hint}</div>
            </div>
        )
    }

    const renderHint = () => {
        if (!hint) return null;
        switch (hintType) {
            case 'block':
                return renderBlockHint()
            case 'floating':
                return renderFloatingHint()
            default:
                return renderBlockHint()
        }
    }

    const renderDynamicPlaceholder = () => {

        if (!placeholder) return null;

        const upperPlaceholderClass = (!!value && !!value.length || typeof value === 'number') ? ' input-custom__field-container__label--upper' : '';

        return (
            <label className={`input-custom__field-container__label${upperPlaceholderClass}`}>
                {placeholder}{!!required && ' *'}
            </label>
        )
    }

    const renderHintTrigger = () => {
        if (!hint) return null;
        return (
            <div className='input-custom__field-container__hint-trigger'>
                <div
                    className='input-custom__field-container__hint-trigger__trigger'
                    onClick={
                        (hintType === 'block' || !hintType)
                            ? () => setShowHint(!showHint)
                            : hintType === 'modal' ?
                                () => showModalDefault({
                                    component: hint,
                                    title: 'Help'
                                })
                                : undefined
                    }
                    ref={hintTrigger}
                >
                    <i
                        className={`far fa-${!!showHint && hintType !== 'floating' ? 'times-circle' : 'question-circle'} input-custom__field-container__hint-trigger__trigger__icon`}
                        aria-hidden='true'
                    />
                </div>
            </div>
        )
    }

    const renderSideIcons = () => {
        return (
            <React.Fragment>
                {
                    iconLeft &&
                    <i className={`${iconLeft} input-custom__field-container__icon-left`} aria-hidden='true'/>
                }
                {
                    iconRight && type !== 'password' &&
                    <i className={`${iconRight} input-custom__field-container__icon-right`} aria-hidden='true'/>
                }
                {
                    type === 'password' &&
                    <i
                        className={`fas fa-${showPassword ? 'eye-slash' : 'eye'} input-custom__field-container__icon-password-eye`}
                        onClick={() => setShowPassword(!showPassword)}
                        aria-hidden='true'
                    />
                }
            </React.Fragment>
        )
    }

    const renderField = () => {

        const leftIconClass = !!iconLeft ? ' input-custom__field-container--left-icon' : '';
        const rightIconClass = !!iconRight ? ' input-custom__field-container--right-icon' : '';
        const disabledClass = !!disabled ? ' input-custom__field-container--disabled' : '';
        const textAreaContainer = type === 'textarea' ? ' input-custom__field-container--textarea' : '';
        const finalType = showPassword && type === 'password' ? 'text' : type;

        return (
            <div
                className={`input-custom__field-container${disabledClass}${leftIconClass}${rightIconClass}${textAreaContainer}`}
            >
                {
                    type !== 'textarea' &&
                    <input
                        id={!!id ? id : undefined}
                        className='input-custom__field-container__field'
                        type={finalType}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        name={name}
                        value={value}
                        onChange={!disabled ? onChange : undefined}
                        required={required}
                        autoFocus={autofocus}
                        disabled={disabled}
                        pattern={pattern}
                        autoComplete={autoComplete}
                        title={title}
                        maxLength={maxLength}
                        minLength={minLength}
                        min={min}
                        max={max}
                        aria-label={ariaLabel}
                        ref={inputField}
                    />
                }
                {
                    type === 'textarea' &&
                    <textarea
                        id={!!id ? id : undefined}
                        className='input-custom__field-container__field input-custom__field-container__textarea'
                        onFocus={onFocus}
                        onBlur={onBlur}
                        name={name}
                        value={value}
                        onChange={!disabled ? onChange : undefined}
                        required={required}
                        autoFocus={autofocus}
                        disabled={disabled}
                        autoComplete={autoComplete}
                        title={title}
                        maxLength={maxLength}
                        minLength={minLength}
                        min={min}
                        max={max}
                        aria-label={ariaLabel}
                        ref={inputField}
                    />
                }
                {renderDynamicPlaceholder()} {/* label must be strictly after input field in DOM*/}
                {renderHintTrigger()}
                {renderSideIcons()}
            </div>
        )
    }

    const renderError = () => {
        if (!error) return null;
        return <div className='input-custom__error'>{error}</div>
    }

    let validatorClass = '';

    if (isValid !== undefined) {
        validatorClass = isValid ? ' input-custom--valid' : ' input-custom--invalid';
    }


    const messages = props.messages
        ? props.messages.filter(m => m.block === id || m.target === id || m.part === id)
        : [];
    let messageType = undefined;

    if (messages.some(m => m.type === 'info')) messageType = 'info';
    if (messages.some(m => m.type === 'success')) messageType = 'success';
    if (messages.some(m => m.type === 'warning')) messageType = 'warning';
    if (messages.some(m => m.type === 'error')) messageType = 'error';

    const messageClassName = messageType ? ` input-custom--${messageType}` : '';

    return (
        <div
            className={`input-custom${validatorClass}${messageClassName}${className ? ' ' + className : ''}`}
            style={{
                ...(style || {}),
                ...variables
            }}
            data-type={messageType}
            data-disabled={disabled}
            data-icon-left={!!iconLeft}
            ref={inputContainer}
        >
            {renderHint()}
            {renderField()}
            {renderError()}
            {
                !!messages.length && !hideMessages &&
                <div className='input-custom__messages'>
                    <Messages messages={messages}/>
                </div>
            }
        </div>
    );
})

export default Input

Input.propTypes = {
    name: PropTypes.string, // required to define prop on change
    placeholder: PropTypes.string, // works as label and placeholder at once. user hints to help user
    className: PropTypes.string, // if visuals should be customised (visualVariables is preferable)
    style: PropTypes.object,
    id: PropTypes.string, // mostly for anchors
    value: PropTypes.oneOfType([ // required to show value from parent state
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),
    messages: PropTypes.array,
    disabled: PropTypes.bool, // if true input will be locked
    type: PropTypes.string, // at this moment use this if password type required or a textarea
    iconLeft: PropTypes.string, // if left icon is required, use it for all inputs in form to keep visuals in one style
    iconRight: PropTypes.string,
    error: PropTypes.oneOfType([ // error message, if custom required, DOM element can be passed
        PropTypes.string,
        PropTypes.element,
    ]),
    hint: PropTypes.oneOfType([ // hint to be displayed in modal, if custom required, DOM element can be passed
        PropTypes.string,
        PropTypes.element,
    ]),
    hintType: PropTypes.oneOf(['block', 'modal', 'floating']),
    showBlockHintOnError: PropTypes.arrayOf(PropTypes.oneOf(['html5', 'propError'])), // triggers hint to open if error of different types was detected
    pattern: PropTypes.string, // default HTML5 validation
    title: PropTypes.string, // HTML5 hover title
    ariaLabel: PropTypes.string, // HTML5 attribute
    minLength: PropTypes.number, // default HTML5 validation
    maxLength: PropTypes.number, // default HTML5 validation
    min: PropTypes.number, // default HTML5 validation
    max: PropTypes.number, // default HTML5 validation
    required: PropTypes.bool,
    isValid: PropTypes.bool, // this prop will visually differ valid and invalid value. undefined if no validation at all, true/false - valid/invalid
    autofocus: PropTypes.bool, // input will be focused on page loading. if multiple inputs have this prop, the last in DOM tree will be autofocused
    autoComplete: PropTypes.string, // input will be focused on page loading. if multiple inputs have this prop, the last in DOM tree will be autofocused
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    passedRef: PropTypes.func,
    visualVariables: PropTypes.exact({ // PLEASE USE PREFERABLY THIS TO REDEFINE STYLES. It allows to re-calculate depending values and scales
        height: PropTypes.string,
        colorBackground: PropTypes.string,
        colorBackgroundHintBlock: PropTypes.string,
        colorFontHintBlock: PropTypes.string,
        colorBorder: PropTypes.string,
        colorBorderHover: PropTypes.string,
        colorIconPassword: PropTypes.string,
        colorAccent: PropTypes.string,
        colorIcon: PropTypes.string,
        colorFont: PropTypes.string,
        colorFontLabel: PropTypes.string,
        colorFontLabelUpper: PropTypes.string,
        borderRadius: PropTypes.string,
        maxHeight: PropTypes.string, // only for textarea type
        minHeight: PropTypes.string, // only for textarea type
        maxWidth: PropTypes.string, // only for textarea type
        minWidth: PropTypes.string, // only for textarea type
    }),
};