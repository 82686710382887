import React from "react";
import PropTypes from 'prop-types';
import styles from './ModalDefault.module.scss'
import { store } from "../../redux/store";
import { addModalLayer, removeLastModalLayer } from "../../redux/actions/ModalActions";
import Button from "../../components/ripple/Button";

export default function showModalDefault({
                                             title,
                                             component,
                                             classNames,
                                             onClose,
                                             maxWidth,
                                             paddingLayout,
                                             paddingTop,
                                             paddingBottom,
                                             backgroundContainer,
                                             borderRadius,
                                             // modal logic
                                             classNameLayer,
                                             appContainerBlur,
                                             modalBackgroundColor,
                                             layerBackgroundBlur,
                                             classNameAppContainer,
                                             classNameModal,
                                             variables,
                                             preventClose,
                                             darker,
                                         }) {

    const isCard = !!darker ? ' ' + styles['container--darker'] : '';

    store.dispatch(addModalLayer({
        component: (
            <div className={`${styles.container}${isCard}${classNames?.container ? ' ' + classNames.container : ''}`}>
                <div className={`${styles.container__header}${classNames?.header ? ' ' + classNames.header : ''}`}>
                    {
                        !preventClose &&
                        <Button
                            className={`${styles.container__header__button}${classNames?.button ? ' ' + classNames.button : ''}`}
                            onClick={() => {
                                if (!preventClose) {
                                    store.dispatch(removeLastModalLayer());
                                    if (onClose) onClose();
                                }
                            }}
                            displayType='text'
                            icon='fas fa-chevron-left'
                            visuals={{
                                borderRadius: '50%',
                                colorBasic: ['var(--colorFontLight)'],
                            }}
                        />
                    }
                    {
                        title &&
                        <p className={`${styles.container__header__title}${classNames?.title ? ' ' + classNames.title : ''}`}>
                            {title}
                        </p>
                    }
                </div>
                <div className={`${styles.container__content}${classNames?.content ? ' ' + classNames.content : ''}`}>
                    <div
                        className={`${styles.container__content__inner}${classNames?.inner ? ' ' + classNames.inner : ''}`}
                    >
                        {component}
                    </div>
                </div>
            </div>
        ),
        classNameModal: `${styles.layout}${classNameModal ? ' ' + classNameModal : ''}`,
        classNameLayer: `${styles.layout__layer}${classNameLayer ? ' ' + classNameLayer : ''}`,
        onClose: onClose,
        layerBackgroundBlur,
        appContainerBlur,
        preventClose,
        modalBackgroundColor: modalBackgroundColor ? modalBackgroundColor : ['rgba(0,0,0,.3)', 'rgba(0,0,0,.6)'],
        variables: {
            ...(maxWidth ? {'--container-max-width': maxWidth} : {}),
            ...(paddingLayout ? {'--layout-padding': paddingLayout} : {}),
            ...(paddingTop ? {'--content-padding-top': paddingTop} : {}),
            ...(paddingBottom ? {'--content-padding-bottom': paddingBottom} : {}),
            ...(borderRadius ? {'--contentBorderRadius': paddingBottom} : {}),
            ...(backgroundContainer?.[0] ? {'--content-background-color-light': backgroundContainer[0]} : {}),
            ...(backgroundContainer?.length ? {'--content-background-color-dark': backgroundContainer[1] || backgroundContainer[0]} : {}),
            ...(variables || {}),
        },
    }))
}

showModalDefault.propTypes = {
    classNames: {
        container: PropTypes.string,
        header: PropTypes.string,
        button: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.string,
        inner: PropTypes.string,
    },
    backgroundContainer: PropTypes.arrayOf(PropTypes.string),
}