import React from "react";

export function filterMessagesBy(messages, container, block, target, part) {
    let result = [];
    messages.map(message => {
        if (!!container && message.container !== container) return;
        if (!!block && message.block !== block) return;
        if (!!target && message.target !== target) return;
        if (!!part && message.part !== part) return;
        result.push(message);
    })
    return result
}

export function getMessagesByType(messages, container, block, target, part) {

    let types = {error: [], warning: [], info: [], success: [],}

    messages.map(message => {
        if (!!container && message.container !== container) return;
        if (!!block && message.block !== block) return;
        if (!!target && message.target !== target) return;
        if (!!part && message.part !== part) return;
        types[message.type in types ? message.type : 'info'].push(message);
    })

    return types
}

export function RenderMessage({list, type}) {

    let icon = 'fas fa-exclamation-triangle';

    if (type === 'info') icon = 'far fa-question-circle';
    if (type === 'success') icon = 'far fa-check-circle';

    return list.map((item, index) => (
        <div className={`block-messages__message block-messages__message--${type}`} key={index}>
            <i className={`${icon} block-messages__message__icon`} aria-hidden='true'/>
            <div className='block-messages__message__text'>{item.message}</div>
        </div>
    ))
}

export default function Messages({messages, container, block, target, part, className, padding}) {

    if (!messages?.length) return null;

    const filtered = filterMessagesBy(messages, container, block, target, part);
    const types = getMessagesByType(filtered);

    return (
        <div
            className={`block-messages${className ? ' ' + className : ''}`}
            style={padding ? {'--block-messages-padding': !!filtered?.length ? padding : '0'} : {}}
        >
            {
                Object.keys(types).map((type, index) => {
                    if (!!types[type]?.length) {
                        return <RenderMessage list={types[type]} type={type} key={index}/>
                    } else return null
                })
            }
        </div>
    )
}